var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("vx-card", [
    _c("div", { staticClass: "grid grid-cols-12 gap-2" }, [
      _c("div", { staticClass: "col-span-12" }, [
        _c("fieldset", { staticClass: "p-2 pt-0 border border-grey" }, [
          _c("legend", { staticClass: "px-1 font-bol" }, [
            _vm._v(" " + _vm._s(_vm.$t("filtros")) + " "),
          ]),
          _c(
            "div",
            { staticClass: "flex items-center p-2 flex-wrap gap-4" },
            [
              _c("select-suggestion", {
                ref: "select_questionnaire",
                class: `flex-grow w-full ${
                  _vm.invalidQuestionnaire ? "required" : ""
                }`,
                attrs: {
                  model: "ProctoringConfigQuestionnaire",
                  label: _vm.$t("Questionnaire"),
                  max: 10,
                  itemTextFormat: (model) => model.questionnaire.name,
                  color: "dark",
                  appendClearOption: true,
                  promptText: _vm.$t("informe-o-nome-para-pesquisar"),
                  placeholderText: _vm.$t("digite-o-nome-do-questionario"),
                },
                model: {
                  value: _vm.filter.config,
                  callback: function ($$v) {
                    _vm.$set(_vm.filter, "config", $$v)
                  },
                  expression: "filter.config",
                },
              }),
              false
                ? _c("select-suggestion", {
                    ref: "select_questionnaire",
                    class: _vm.invalidQuestionnaire
                      ? "required flex-grow"
                      : "flex-grow",
                    attrs: {
                      column: "name,email",
                      model: "User",
                      color: "dark",
                      label: _vm.$t("usuario"),
                      appendClearOption: true,
                      placeholderText: _vm.$t(
                        "digite-o-nome-ou-email-do-usuario"
                      ),
                    },
                    model: {
                      value: _vm.filter.user,
                      callback: function ($$v) {
                        _vm.$set(_vm.filter, "user", $$v)
                      },
                      expression: "filter.user",
                    },
                  })
                : _vm._e(),
              _vm.userRepository
                ? _c(
                    "vs-select",
                    {
                      ref: "userSelect",
                      staticClass: "flex-grow",
                      attrs: {
                        placeholder: _vm.$t("selecione-usuarios-para-filtrar"),
                        autocomplete: "",
                        label: _vm.$t("usuario"),
                      },
                      model: {
                        value: _vm.filter.user,
                        callback: function ($$v) {
                          _vm.$set(_vm.filter, "user", $$v)
                        },
                        expression: "filter.user",
                      },
                    },
                    _vm._l(_vm.userRepository.objects, function (item, index) {
                      return _c("vs-select-item", {
                        key: index,
                        attrs: {
                          value: item.id,
                          text: `${item.name} <br/><div class=\'font-bold text-sm\'>${item.email}<div>`,
                        },
                      })
                    }),
                    1
                  )
                : _vm._e(),
              _c(
                "vs-select",
                {
                  attrs: { label: _vm.$t("processamento-de-video") },
                  model: {
                    value: _vm.filter.processStatus,
                    callback: function ($$v) {
                      _vm.$set(_vm.filter, "processStatus", $$v)
                    },
                    expression: "filter.processStatus",
                  },
                },
                [
                  _c("vs-select-item", {
                    attrs: { text: "Nenhum", value: null },
                  }),
                  _c("vs-select-item", {
                    attrs: { text: "Não contém vídeo", value: "N" },
                  }),
                  _c("vs-select-item", {
                    attrs: { text: "Não Iniciado", value: "P" },
                  }),
                  _c("vs-select-item", {
                    attrs: { text: "Em Andamento", value: "I" },
                  }),
                  _c("vs-select-item", {
                    attrs: { text: "Completo", value: "F" },
                  }),
                ],
                1
              ),
              _c("div", { staticClass: "grid" }, [
                _c(
                  "label",
                  { staticClass: "col-span-3 p- font-medium text-grey" },
                  [
                    _vm._v(
                      " " + _vm._s(_vm.$t("criticidade-dos-eventos")) + " "
                    ),
                  ]
                ),
                _c("div", { staticClass: "flex gap-2 flex-grow-0" }, [
                  _c(
                    "div",
                    {
                      class: _vm.severityFilterVisuals("L", "black"),
                      on: {
                        click: function ($event) {
                          _vm.filter.severity = "L"
                        },
                      },
                    },
                    [
                      _c("font-awesome-icon", {
                        staticClass: "h-4 w-4 text-success",
                        attrs: { icon: "exclamation" },
                      }),
                      _c("span", { staticClass: "text-success" }, [
                        _vm._v(_vm._s(_vm.$t("baixa"))),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      class: _vm.severityFilterVisuals("M", "black"),
                      on: {
                        click: function ($event) {
                          _vm.filter.severity = "M"
                        },
                      },
                    },
                    [
                      _c("font-awesome-icon", {
                        staticClass: "h-4 w-4 text-warning",
                        attrs: { icon: "exclamation-circle" },
                      }),
                      _c("span", { staticClass: "text-warning" }, [
                        _vm._v(_vm._s(_vm.$t("base_question.difficulty.M"))),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      class: _vm.severityFilterVisuals("H", "black"),
                      on: {
                        click: function ($event) {
                          _vm.filter.severity = "H"
                        },
                      },
                    },
                    [
                      _c("font-awesome-icon", {
                        staticClass: "h-4 w-4 text-danger",
                        attrs: { icon: "exclamation-triangle" },
                      }),
                      _c("span", { staticClass: "text-danger" }, [
                        _vm._v(_vm._s(_vm.$t("alta"))),
                      ]),
                    ],
                    1
                  ),
                ]),
              ]),
              _c(
                "div",
                { staticClass: "flex gap-1 ml-auto" },
                [
                  _c(
                    "vs-checkbox",
                    {
                      staticClass: "text-sm smaller-checkbox",
                      model: {
                        value: _vm.autoFilter,
                        callback: function ($$v) {
                          _vm.autoFilter = $$v
                        },
                        expression: "autoFilter",
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("automatico")))]
                  ),
                  _c(
                    "vs-button",
                    {
                      staticClass: "py-auto px-4",
                      attrs: { disabled: !_vm.filter.config },
                      on: { click: _vm.reloadGrid },
                    },
                    [_vm._v(_vm._s(_vm.$t("filtrar")))]
                  ),
                  _c(
                    "vs-button",
                    {
                      staticClass: "py-auto px-4",
                      attrs: { type: "border", disabled: !_vm.isFiltering },
                      on: { click: _vm.clearFilters },
                    },
                    [_vm._v(_vm._s(_vm.$t("action.clear")))]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]),
      ]),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.filter.config,
              expression: "filter.config",
            },
          ],
          staticClass: "col-span-12",
        },
        [
          _c("Grid", {
            ref: "grid",
            attrs: {
              service: _vm.service,
              route_name: "proctoring_config",
              order_column: "updated_at",
              hide_searchbar: true,
              route_grid_path: "/classroom_user/grid",
            },
            scopedSlots: _vm._u([
              {
                key: "gridThead",
                fn: function () {
                  return [
                    _c(
                      "vs-th",
                      {
                        key: "user",
                        staticClass: "w-64 sm:w-64 md:w-72 lg:w-80 xl:w-96",
                      },
                      [_vm._v(" " + _vm._s(_vm.$t("usuario")) + " ")]
                    ),
                    _c("vs-th", { key: "started_at" }, [
                      _vm._v(" " + _vm._s(_vm.$t("data-inicio")) + " "),
                    ]),
                    _c("vs-th", { key: "finished_at" }, [
                      _vm._v(" " + _vm._s(_vm.$t("data-fim")) + " "),
                    ]),
                    _c("vs-th", { key: "events" }, [
                      _vm._v(" " + _vm._s(_vm.$t("eventos")) + " "),
                    ]),
                    _c("vs-th", { key: "video_progress" }, [
                      _vm._v(" " + _vm._s(_vm.$t("video")) + " "),
                    ]),
                  ]
                },
                proxy: true,
              },
              {
                key: "filterZone",
                fn: function () {
                  return undefined
                },
                proxy: true,
              },
              {
                key: "gridActions",
                fn: function () {
                  return [
                    _c("vs-th", { key: "actions" }, [
                      _vm._v(_vm._s(_vm.$t("Actions"))),
                    ]),
                  ]
                },
                proxy: true,
              },
              {
                key: "gridData",
                fn: function (props) {
                  return _vm._l(props.data, function (tr, indextr) {
                    return _c(
                      "vs-tr",
                      { key: indextr, attrs: { data: tr } },
                      [
                        _c("vs-td", { key: "user" }, [
                          _c(
                            "div",
                            {
                              staticClass:
                                "grid w-64 sm:w-64 md:w-72 lg:w-80 xl:w-96 gap-x-2 items-center justify-start",
                            },
                            [
                              _c("vs-avatar", {
                                staticClass: "m-0 pl-0 col-start-1 row-span-2",
                                attrs: { src: tr.user.profile_picture },
                              }),
                              _c(
                                "span",
                                {
                                  directives: [
                                    {
                                      name: "tooltip",
                                      rawName: "v-tooltip.auto",
                                      value: {
                                        content: tr.user.name,
                                        delay: { show: 100, hide: 100 },
                                      },
                                      expression:
                                        "{\n                    content: tr.user.name,\n                    delay: { show: 100, hide: 100 }\n                  }",
                                      modifiers: { auto: true },
                                    },
                                  ],
                                  staticClass:
                                    "truncate block col-start-2 row-start-1",
                                },
                                [_vm._v(" " + _vm._s(tr.user.name) + " ")]
                              ),
                              _c(
                                "span",
                                {
                                  directives: [
                                    {
                                      name: "tooltip",
                                      rawName: "v-tooltip.auto",
                                      value: {
                                        content: tr.user.email,
                                        delay: { show: 100, hide: 100 },
                                      },
                                      expression:
                                        "{\n                    content: tr.user.email,\n                    delay: { show: 100, hide: 100 }\n                  }",
                                      modifiers: { auto: true },
                                    },
                                  ],
                                  staticClass:
                                    "truncate block col-start-2 row-start-2",
                                },
                                [_vm._v(" " + _vm._s(tr.user.email) + " ")]
                              ),
                            ],
                            1
                          ),
                        ]),
                        _c("vs-td", { key: "started_at" }, [
                          _vm._v(
                            " " +
                              _vm._s(_vm.$utils.format.date(tr.created_at)) +
                              " "
                          ),
                        ]),
                        _c("vs-td", { key: "finished_at" }, [
                          _vm._v(
                            " " +
                              _vm._s(_vm.$utils.format.date(tr.finished_at)) +
                              " "
                          ),
                        ]),
                        _c("vs-td", { key: "events" }, [
                          _c("div", { staticClass: "flex flex-col gap-0" }, [
                            tr.event_count && tr.event_count.L > 0
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "text-success flex flex-row justify-between gap-1",
                                  },
                                  [
                                    _c("label", [
                                      _vm._v(_vm._s(_vm.$t("baixo"))),
                                    ]),
                                    _c("label", [
                                      _vm._v(_vm._s(tr.event_count.L)),
                                    ]),
                                  ]
                                )
                              : _vm._e(),
                            tr.event_count && tr.event_count.M > 0
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "text-warning flex flex-row justify-between gap-1",
                                  },
                                  [
                                    _c("label", [
                                      _vm._v(_vm._s(_vm.$t("medio"))),
                                    ]),
                                    _c("label", [
                                      _vm._v(_vm._s(tr.event_count.M)),
                                    ]),
                                  ]
                                )
                              : _vm._e(),
                            tr.event_count && tr.event_count.H > 0
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "text-danger flex flex-row justify-between gap-1",
                                  },
                                  [
                                    _c("label", [
                                      _vm._v(_vm._s(_vm.$t("alto"))),
                                    ]),
                                    _c("label", [
                                      _vm._v(_vm._s(tr.event_count.H)),
                                    ]),
                                  ]
                                )
                              : _vm._e(),
                          ]),
                        ]),
                        _c(
                          "vs-td",
                          { key: "video_progress", attrs: { width: "10%" } },
                          [
                            _vm._v(
                              " " + _vm._s(_vm.videoProgressText(tr)) + " "
                            ),
                          ]
                        ),
                        _c(
                          "vs-td",
                          { key: "actions", attrs: { width: "13%" } },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "grid grid-cols-3 gap-8 sm:gap-4 md:gap-4",
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "flex items-center justify-center",
                                  },
                                  [
                                    _c("feather-icon", {
                                      directives: [
                                        {
                                          name: "permission",
                                          rawName: "v-permission",
                                          value: "proctoring.audit.view",
                                          expression: "'proctoring.audit.view'",
                                        },
                                        {
                                          name: "tooltip",
                                          rawName: "v-tooltip.auto",
                                          value: {
                                            content: "Abrir Auditoria",
                                            delay: { show: 100, hide: 100 },
                                          },
                                          expression:
                                            "{\n                      content: 'Abrir Auditoria',\n                      delay: { show: 100, hide: 100 }\n                    }",
                                          modifiers: { auto: true },
                                        },
                                      ],
                                      attrs: {
                                        svgClasses:
                                          "h-5 w-5 mb-1 stroke-current text-primary",
                                        icon: "EyeIcon",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.open(tr)
                                        },
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "flex items-center justify-center",
                                  },
                                  [
                                    _c("feather-icon", {
                                      directives: [
                                        {
                                          name: "permission",
                                          rawName: "v-permission",
                                          value:
                                            "proctoring.audit.force_process",
                                          expression:
                                            "'proctoring.audit.force_process'",
                                        },
                                        {
                                          name: "tooltip",
                                          rawName: "v-tooltip.auto",
                                          value: {
                                            content: "Processar vídeo",
                                            delay: { show: 100, hide: 100 },
                                          },
                                          expression:
                                            "{\n                      content: 'Processar vídeo',\n                      delay: { show: 100, hide: 100 }\n                    }",
                                          modifiers: { auto: true },
                                        },
                                      ],
                                      attrs: {
                                        svgClasses:
                                          "h-5 w-5 mb-1 stroke-current text-primary",
                                        icon: "VideoIcon",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.forceProctoringProccess(tr)
                                        },
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "flex items-center justify-center",
                                  },
                                  [
                                    _c(
                                      "vs-dropdown",
                                      {
                                        staticClass: "cursor-pointer",
                                        attrs: {
                                          "vs-custom-content": "",
                                          "vs-trigger-click": "",
                                        },
                                      },
                                      [
                                        _c("feather-icon", {
                                          attrs: {
                                            type: "filled",
                                            icon: "MoreVerticalIcon",
                                          },
                                        }),
                                        _c(
                                          "vs-dropdown-menu",
                                          [
                                            !tr.finished_at
                                              ? _c("vs-dropdown-item", [
                                                  _c(
                                                    "div",
                                                    {
                                                      directives: [
                                                        {
                                                          name: "permission",
                                                          rawName:
                                                            "v-permission",
                                                          value:
                                                            "proctoring.audit.video_in_processing",
                                                          expression:
                                                            "'proctoring.audit.video_in_processing'",
                                                        },
                                                      ],
                                                      staticClass: "flex",
                                                      staticStyle: {
                                                        width: "150px",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.videoInProcessing(
                                                            tr
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass: "w-2/12",
                                                        },
                                                        [
                                                          _c("feather-icon", {
                                                            attrs: {
                                                              icon: "RefreshCcwIcon",
                                                              svgClasses:
                                                                "text-warning h-5 w-5 mb-1 stroke-current",
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "w-10/12",
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "flex bg-gray-200 float-left",
                                                            },
                                                            [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    _vm.$t(
                                                                      "action.video_in_processing"
                                                                    )
                                                                  ) +
                                                                  " "
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                ])
                                              : _vm._e(),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            ),
                          ]
                        ),
                      ],
                      1
                    )
                  })
                },
              },
            ]),
          }),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }